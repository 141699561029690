let database = [
  {
    page: "MainCourse",
    id: 1000,
    name: "Gnocchi di zucca",
    dateOfTheRecipe: "Aprile 6, 2022",
    description:
      "Un classico della cucina delle nostre nonne, in versione moderna. Per essere più precisa: CAVATELLI alla zucca. E’ una tipica pasta fresca del sud Italia, realizzata con l’unione di acqua e farina. Ha una forma allungata e incavata all’interno ed è un tipico piatto del pranzo domenicale. La zucca è un ortaggio con molte proprietà e benefici. Ricca di sostanze antiossidanti, di vitamina E e C di betacarotene, utili per rinforzare il sistema immunitario. Contiene licopene, una molecola con proprietà antitumorali con benefici nei confronti del tumore alla prostata, al polmone e allo stomaco. Ha un alto contenuto di potassio ed è consigliata per chi ha problemi di stitichezza ed irregolarità intestinale per le sue proprietà lassative e l’ elevata presenza di fibre. Ha un basso carico glicemico ed è un alimento consigliato per chi è diabetico. ",

    ingredients: "Zucca cotta (200 gr), farina (250 gr)",
    steps:
      "Cucinare la zucca, dopodiché renderla a purea e fare raffreddare. Sul piano di lavoro o contenitore inserire gli ingredienti un po' alla volta. Il composto finale deve risultare morbido, ma non appiccicoso. Formare dei cordoncini e tagliare gli gnocchi. Quando l'acqua bolle calare gli gnocchi e appena salgono a galla raccoglierli e condirli col condimento che desiderate. Consiglio: La bio.nutrizionista consiglia di cucinare la zucca il giorno prima.",

    image: { src: "/images/gnocchizucca.png", alt: "gnocchizucca" },
  },

  {
    page: "SecondCourse",
    id: 1001,
    dateOfTheRecipe: "Aprile 6, 2022",
    name: "Alici a beccafico",
    date: "Aprile 6, 2022",
    description:
      "Il pesce azzurro è un alimento con ottime caratteristiche nutrizionali. Ricco di acidi grassi Omega-3, i quali aiutano a prevenire le malattie cardiovascolari, con riduzione del colesterolo LDL,”cattivo”, e aumento del colesterolo HDL, “buono”. Ha una buona quantità di proteine e di minerali, in particolare del Selenio e Calcio.",

    ingredients:
      "200gr di alici sarde fresche, 40gr di pane di grano duro, 5 mandorle, scorza di limone, un cucchiaino di olio ed un pizzico di sale.",
    steps:
      "Pulire ed asciugare bene le alici. Frullare il pane, le mandorle ed un po' di buccia di limone. Aggiungere sale, olio e succo di limone. Riempire i filetti di alici con una piccola manciata di ripieno. Disponili su carta forno, tra un involtino e l’altro inserire la scorza di limone rimanente. Se vi rimane del ripieno aggiungetelo sugli involtini. Un filo d'olio e via in forno a 200° con grill fino a doratura. Taglia a listarelle la scorza del limone privata della parte bianca. Inserirla in acqua e cambiare l’acqua un paio di volte.",

    image: { src: "/images/alici.png", alt: "alici" },
  },

  {
    page: "Dessert",
    id: 1002,
    name: "La torta di Mele",
    dateOfTheRecipe: "Aprile 6, 2022",
    description:
      "Quando arriva l'autunno subito mi viene in mente la torta di mele, protagonista dei pomeriggi con il suo profumo delicato e irresistibile. Dopo vari tentativi sono riuscita a sfornare una torta super soffice, golosa e genuina. 'Una mela al giorno toglie il medico di giorno' così recita un famoso detto. E’ un'alleata perfetta per il benessere fisico ricca di minerali, vitamine e fibre. Consumata quotidianamente aiuta a regolare il colesterolo. Inoltre, aiuta il processo di digestione grazie alla presenza della Vitamina B2.",

    ingredients:
      "2 uova, 30 ml di olio di semi, 150 ml di latte, 50gr di zucchero, 16 gr di lievito per dolci setacciato, cannella setacciata (q.b) e 3 mele.",

    steps:
      "Lavorare le uova con lo zucchero. Unire l’olio, il latte e, a varie riprese, la farina con il lievito e cannella. Tagliare a cubetti piccoli una mela e inserirla nell’impasto. Versare il composto in uno stampo di 18 cm e sistemare le fettine di mele sottili sulla torta. Forno preriscaldato a 180° modalità ventilato per 40 minuti circa. Consiglio: La bio.nutrizionista consiglia di setacciare gli ingredienti in polvere, così da non avere grumi nelll’impasto.",
    image: { src: "/images/torta_di_mele_2.png", alt: "torta di mele" },
  },

  {
    page: "SecondCourse",
    id: 1003,
    name: "Parmigiana di Carciofi",
    dateOfTheRecipe: "Aprile 6, 2022",
    description:
      "I carciofi sono ANTIOSSIDANTI, DEPURATIVI  e DIURETICI. I carciofi contengono un'elevata quantità di acqua che stimola la diuresi, inoltre l'elevata presenza di fibre riduce l’accumulo di grassi a livello intestinale ed esplica azione lassativa. Buona fonte di minerali come potassio, ferro, fosforo e calcio e proteine in particolare del gruppo B. E’ un alimento che aiuta a tenere i valori glicemici bassi. Il carciofo è noto per essere un alimento benefico del fegato, grazie alla CINARINA, che protegge il fegato dagli attacchi di radicali liberi  e stimola la rigenerazione delle cellule epatiche. Questo alimento è sconsigliato ai soggetti che soffrono di coliche biliari.",

    ingredients:
      "200gr di carciofi, 40gr di mozzarella/formaggio, 5gr di burro, un cucchiaino d'olio ed un pizzico di sale.",
    steps:
      "Pulire i carciofi e tagliarli a fettine, inserire i carciofi puliti in acqua e limone per cinque minuti. In una padella mettete un filo d'olio e aglio. Aggiungete i carciofi e due bicchieri d'acqua. Coprire tutto con un coperchio. Fate cucinare fino a quando i carciofi non risulteranno morbidi. Raffreddate i carciofi e tagliate 200 grammi di mozzarella o formaggio. Imburrate una teglia e formare uno strato di carciofi nella teglia seguito da uno strato di mozzarella o formaggio e ripetere. Finire con una spolverata di formaggio grattugiato, cuocere in forno ventilato a 180° per 15/20 minuti. Consigli: La consiglia di rendere la mozzarella asciutta in modo che non rilascia troppa acqua. E’ possibile tenerla in uno scolapasta in frigo, per eliminare il suo liquido.",

    image: { src: "/images/carciofi.png", alt: "carciofi" },
  },

  {
    page: "SecondCourse",
    id: 1004,
    name: "Involtini di tacchino",
    dateOfTheRecipe: "Aprile 6, 2022",
    description:
      "Un incontro di sapori contrastanti; la dolcezza della zucca e  l’amarognolo del radicchio trovano un equilibrio di sapori nell'involucro della carne bianca del tacchino. Ecco un'idea diversa per preparare una pietanza apparentemente triste, ma super colorata, nutriente e golosa",

    ingredients:
      "400gr di petto di tacchino a fette, 15 foglie di radicchio di trevignano, 400gr di polpa di zucca, 5 foglie di Slavia, 20gr di parmigiano grattuggiato, 1 spicchio di aglio/cipollotto, una tazzina di vino bianco, olio extra vergine q.b., sale q.b, pepe q.b. ed un cubetto di gorgonzola. ",
    steps:
      "Per prima cosa lavate e pulite la zucca, tagliatela a cubetti e cucinatela in una padella antiaderente con un filo d'olio e aglio o cipollotto. Coprite con il coperchio e lasciare cucinare, se occorre aggiungete dell'acqua e lasciate raffreddare bene. Nel frattempo prendete 7 foglie di radicchio e sbollentatele per qualche secondo in abbondante acqua. In un contenitore inserite la zucca fredda, parmigiano, salvia, pepe e pizzico di sale amalgamate il tutto con la forchetta. Sul piano da lavoro adagiate le fettine di tacchino, inserite il composto di zucca, qualche foglia cruda di radicchio e un pò di gorgonzola e arrotolate la carne. Ricoprite l’involtino con una o due foglie di radicchio sbollentate e legate il fagottino con del filo da cucina. Nella pentola dove avete cotto la zucca inserite un filo d'olio e inserite gli involtini; fate cucinare per qualche minuto e poi aggiungete la tazzina di vino bianco fate cucinare col coperchio, se necessità aggiungete dell'acqua calda oppure del brodo vegetale.Servite gli involtini con della verdura.",
    image: { src: "/images/involtini2.png", alt: "involtini di tacchino" },
  },

  {
    page: "Dessert",
    id: 1005,
    name: "Gallette di mele",
    dateOfTheRecipe: "Aprile 6, 2022",
    description: "Sana e semplice merenda per il giorno",

    ingredients:
      "2 mele Annurca, 180gr di farina 0, 30gr di noci tritate, 2 cucchiai di zucchero di canna, 50gr di olio di semi, 65gr d’acqua fredda, un pugno di uva sultanina, ½ cucchiaini di limone, sale q.b., e ½ cucchiaini di cannella, latte q.b ",
    steps:
      "In una ciotola inserire farina, noci tritate, un pizzico di sale e due cucchiai di zucchero di canna. Aggiungere l'olio a filo ed impastare. Aggiungere l’acqua fredda e formate un panetto omogeneo, non lavorate troppo l’impasto. Coprite il panetto con della pellicola trasparente per alimenti e lasciate riposare in frigorifero per almeno 30 minuti. Tagliate a fettine le mele e aggiungete il succo di mezzo limone, la cannella e un cucchiaio di zucchero di canna. Stendere il panetto tra due fogli di carta forno dello spessore di 4-5 millimetri circa. Bucherellate la base della sfoglia e aggiungete le mele tagliate a fettine lasciando libero il bordo in modo da poterlo ripiegare su se stesso. Spennellate il bordo con il latte e cospargete la superficie della galette l'uva sultanina precedentemente ammollata. Forno preriscaldato a 170° C  per 40 minuti circa.",
    image: { src: "/images/gallettedimele.png", alt: "gallette di mele" },
  },

  {
    page: "SecondCourse",
    id: 1006,
    name: "Pollo croccante",
    dateOfTheRecipe: "Aprile 6, 2022",
    description:
      "Ogni volta che si parla di pollo la nostra mente ci riporta ad  una triste fettina arrostita, in realtà non è così. In cucina é molto importante sperimentare e provare nuove ricette divertirsi e soprattutto mai avere un pregiudizio. Che dite di provare a preparare queste cosce di pollo?",

    ingredients: "Cosce di pollo, corn-flakes tritati e yogurt greco",
    steps:
      "Togliere la pelle, stendere un filo di yogurt greco bianco sulla carne e passare la cosce nei corn-flakes tritati, io li aromatizzo con del rosmarino. Forno preriscaldato modalità ventilata 180° per 20 minuti, controllare cottura. Microonde modalità frittura light: sul piatto crisp fio d'olio.",
    image: { src: "/images/pollocroccante.png", alt: "Pollo croccante" },
  },
  {
    page: "SecondCourse",
    id: 1007,
    name: "Polpette di pesce spada alla ghiotta",
    dateOfTheRecipe: "Aprile 6, 2022",
    description:
      "Un piatto della tradizione siciliana. Si tratta di pesce tritato e amalgamato con pane e aromi vari. Una buona alternativa di secondo semplice da realizzare e gustoso che conquisterà i palati sia per grandi che piccini.",

    ingredients:
      "500gr di pesce spada, 100gr di mollica di pane raffermo, 2 foglie di  alloro, 1 uovo, 2 cucchiaini di Parmigiano reggiano o Grana, 1 scalogno o una cipolla piccola, 1 litro di passata di pomodoro, sale q.b., pepe q.b., olive nere q.b., capperi q.b., basilico q.b. ",
    steps:
      "Sbollentarlo, in acqua bollente salata, insieme alle foglie di alloro. Lessate il pesce spada per circa 10 minuti. Al termine, scolatelo e frullatelo con l'aiuto di un mixer. In un contenitore inserire la polpa frullata del pesce spada, l'uovo, formaggio grattugiato, pizzico di sale e di pepe nero e la mollica di pane, aggiunto a poco a poco. Dovete ottenere un impasto morbido e asciutto. Se non serve quest'ultimo alimento. In una pentola inserire filo d'olio con scalogno, capperi, olive, la passata di pomodoro e le polpette di pesce spada.Fate cucinare il tutto.",
    image: {
      src: "/images/polpette_di_pesce_spada.png",
      alt: "Polpette di pesce spada",
    },
  },
  {
    page: "Dessert",
    id: 1008,
    name: "Succo di melograna",
    dateOfTheRecipe: "Aprile 6, 2022",
    description:
      "Oggi parliamo di un superfood dell'autunno il Melograno, anzi Melograna. Col termine melograno intendiamo la pianta, mentre se vogliamo parlare del frutto diremmo melograna. E’ un frutto molto antico. Fin dai greci e’ stato un simbolo di fertilità e ricchezza, la melagrana rappresenta uno dei frutti più antichi, dalle molteplici proprietà nutritive.Ricco di potassio, ferro, rame, manganese, fosforo, Vitamina A, vitamina E e C. Elevato contenuto in polifenoli con un potere antiossidante. Rafforza i vasi sanguigni e previene le malattie cardiovascolari; aiuta a ridurre il colesterolo. Rinforza il sistema immunitario e ha un’azione antinfiammatoria.",

    ingredients: "Melograna",
    steps:
      "Sbucciare il melograno. Inserire i chicchi di un melograno all’interno dell’estrattore e azionatelo.Consigli: La bio.nutrizionista consiglia di consumare questo estratto appena fatto in modo che le proprietà nutritive non si perdono.",
    image: {
      src: "/images/succodimelograna.png",
      alt: "Succo di melograna",
    },
  },

  {
    page: "Dessert",
    id: 1009,
    name: "Biscotti alle noci",
    dateOfTheRecipe: "Aprile 6, 2022",
    description:
      "Ad autunno si raccolgono le noci, frutta secca oleosa da proprietà nutritive eccezionali. Le noci sono delle alleate del cuore e prevengono malattie cardiovascolari. Ricca di acidi grassi Omega-3. E’ un alimento super energetico. Aiuta a ridurre il colesterolo. Contiene un’elevata quantità di sali minerali come potassio, ferro,calcio,fosforo e Vitamine del gruppo B - E - A ",

    ingredients:
      "300gr di farina di riso, 100gr di zucchero, 1 uovo intero, 50gr di noci tritate e 60gr di olio di semi.",
    steps:
      "Lavorate gli ingredienti, avvolgete l'impasto nella pellicola e fate riposare in frigo. Non preoccupatevi se l'impasto è un po' molliccio, non è facile gestire le farine prive di glutine. Stendere e formare i vostri biscotti. Forno preriscaldato a 180° per 20 minuti circa. Consigli: La bio.nutrizionista consiglia di utilizzare l’uovo a temperatura ambiente. ",
    image: {
      src: "/images/biscottinoci.png",
      alt: "Biscotti di noci",
    },

    benefits: [
      { type: "Riduce il rischio di patologie cardiovascolari e coronariche" },
      { type: "Ha un elevato contenuto di Omega-3, grassi essenziali" },
      { type: "Vitamine del gruppo B-E-A" },
      { type: "Sali minerali: potassio-ferro-calcio-fosforo." },
    ],
  },

  {
    page: "Dessert",
    id: 1010,
    name: "La torta di carote e mandorle",
    dateOfTheRecipe: "Maggio 8, 2022",
    description:
      "Una dolce coccola per iniziare la giornata nel modo giusto e rendere la colazione più gustosa e irresistibile.",

    ingredients:
      "4 uova intere a temperatura ambiente, 200gr di zucchero, 300gr di carote, 300gr di mandorle tritate, 80gr di farina setacciata, buccia di limone, un pizzico di sale ed una bustina di lievito per dolci setacciata.",

    steps:
      "Pulire, sbucciare e tritare le carote. Lavorare le uova con lo zucchero usando le frustre. Quando il composto e' spumoso aggiungere le carote, mandorle, farina, lievito e sale. Teglia da 18 cm imburrata con burro e pangrattato e far cuocere in forno preriscaldato a 180° per 30 minuti -fare sempre controllo cottura",
    image: { src: "/images/torta_di_carote.png", alt: "torta di carote" },
  },
  {
    page: "Dessert",
    id: 1011,
    name: "Ciambella agli albumi e cioccolata",
    dateOfTheRecipe: "Giugno 22, 2022",
    description:
      "Ecco una ricetta antispreco per riutilizzare gli albumi. Un dolce soffice e gustoso che delizierà i vostri palati, da poter mangiare a colazione o merenda.",

    ingredients:
      "200g di farina, 200g di albumi, 160g di zucchero, 80ml d’olio di semi di girasole, 60ml d’acqua oppure latte,  una bustina di lievito in polvere per dolci, 100g di cioccolato a pezzi ed un pizzico di sale.",
    steps:
      "Per prima cosa tagliare il cioccolato e mettere in congelatore per 30 minuti. Con l’aiuto delle fruste elettriche lavorare albumi con un pizzico di sale. Dopo qualche minuto aggiungere lo zucchero e lasciate lavorare il tutto fin quando gli albumi non risultano montati a neve ben ferma. A questo punto inserire gli ingredienti secchi - Farina e Lievito- che precedentemente  avete setacciati. Aggiungere gli ingredienti liquidi -Olio e Acqua/Latte fate lavorare il tutto. Imburrate una teglia rotonda di diametro 22/24 cm e fate cucinare in forno preriscaldato per 30 minuti a 180°. Fare sempre la prova stecchino. Consigli della bio.nutrizionista: Gli albumi devono essere a temperatura ambiente e fateli lavorare per almeno 30 minuti con lo zucchero, così che il vostro dolce sarà sofficissimo. Il cioccolato, potete inserire quello che desiderate, preferibile il fondente, ma se a casa non lo avete - com’è capitato a me-  non è un problema.",
    image: {
      src: "/images/ciambellaagrumi.png",
      alt: "Ciambella agli agrumi e cioccolata",
    },
  },
  {
    page: "Dessert",
    id: 1012,
    name: "Ciambella alla barbabietola",
    dateOfTheRecipe: "Luglio 15, 2022",
    description:
      "Un dolce sano e goloso, super morbido al tatto e da un profumo irresistibile. Adatto per le vostre colazioni o spuntini giornalieri. La barbabietola è ricca di acqua ed è un’ottima fonte di fibre, antiossidanti, acido folico (vitamina B9), potassio e vitamina C. Contiene la betanina, un pigmento rosso che appartiene al gruppo delle betalaine, ha proprietà antiossidanti, soprattutto a carico del colesterolo LDL, e i possibili effetti positivi sulla salute. Sono tante le proprietà della barbabietola: aiuta a ridurre l'ipertensione; svolge azione detox; alleata del nostro intestino essendo ricca di fibre aiuta a prevenire la stitichezza e migliora la regolarità dell’apparato gastro-intestinale; favorisce la salute del fegato grazie alla presenza di betaina (pigmento più comune delle barbabietole, appartenente al gruppo delle betalaine, è responsabile del loro colore rosso intenso) che agisca andando a ridurre i grassi accumulati nel fegato. E’ sconsigliato l’uso a chi soffre di gastrite, dato che aumenta la produzione di succhi gastrici, a chi ha problemi di calcoli ai reni, dato che le rape sono ricche di ossalati e diabetici perché, soprattutto cotta, la barbabietola contiene zuccheri.",

    ingredients:
      "200 g barbabietole precotte, 100 g olio di semi di mais, 80 g zucchero, 3 uova, 100 gr cioccolato fondente/gocce di cioccolato, 120 g farina 00 + q.b. per infarinare le gocce, 16 g lievito chimico in polvere, 15 g cacao amaro in polvere.",
    steps:
      "Eliminare la buccia esterna delle barbabietole. Tagliare la polpa a dadini e frullare con 15 gr di olio. In una ciotola montare le uova intere con lo zucchero, lavorare il composto per 10 minuti, fino a quando non risulterà ben spumoso. Versare le barbabietole frullate. Amalgamare e aggiungere a filo l’olio restante. Aggiungere la farina, il lievito e il cacao amaro in polvere e incorporare poco alla volta all’impasto. Per ultime, le gocce di cioccolato che avete infarinate ed eliminare la farina in eccesso. Versare l’impasto nella tortiera 20 cm, cuocere in forno statico preriscaldato a 180° per 40 minuti. Fare la prova stecchino. La nutrizionista consiglia di conservare il dolce in frigo se viene fatto in estate e di consumarlo entro 3 / 4  giorni.",
    image: {
      src: "/images/ciambellabarbabietola.PNG",
      alt: "Ciambella alla barbabietola",
    },
  },
  {
    page: "SecondCourse",
    id: 1013,
    name: "Peperoni ripieni di tonno",
    dateOfTheRecipe: "Luglio 15, 2022",
    description:
      "Un piatto tipico campano e dall' azione multivitaminica! A Napoli, in dialetto si indica con `puparuolo mbuttunato`, di solito si riempie con un impasto di carne, uova , salumi o salsicce. Oggi voglio darvi una variante, pratica e gustosa, diversa dalla solita. I peperoni sono ortaggi estivi, ricchi di acqua, ma soprattutto fonte di vitamina C.",

    ingredients:
      "2 peperoni piccoli oppure 1 peperone grande, 100 gr di tonno al naturale, un pizzico di sale, pepe e prezzemolo se piace.",
    steps:
      "Per prima cosa lavate bene i peperoni, dividete i peperoni a metà, eliminate i semi, i filamenti interni, e fateli leggermente scottare in una pentola con dell'acqua bollente. Quando sono pronti, scolateli e lasciateli raffreddare e asciugare. In un contenitore inserite il tonno con un filo d’olio, prezzemolo tritato, pepe e sale e fate amalgamare il tutto. Riempite i peperoni con la farcia e adagiarli su una teglia ricoperta con della carta forno. Cuocete in forno preriscaldato ventilato a 180°C per 15-20 minuti. La nutrizionista consiglia per chi ha problemi di digestione di privare i peperoni dei loro semi interni, ma soprattutto della loro pelle.",
    image: {
      src: "/images/peperoniripieni.PNG",
      alt: "Peperoni ripieni di tonno",
    },
  },
  {
    page: "Dessert",
    id: 1014,
    name: "Plumcake con frutta congelata",
    dateOfTheRecipe: "Luglio 15, 2022",
    description:
      "Un dolce soffice e profumatissimo che può essere consumato sia per colazione sia per un goloso spuntino. In freezer c’erano dei mirtilli surgelati raccolti in estate, a volte capita un esubero di frutta stagionale, così per averne durate la restante parte di anno si può congelare! Ecco un modo per consumare  i mirtilli, come variante alla solita granita!",

    ingredients:
      "2 uova medie, 80 gr di zucchero integrale, 80 gr di succo di limone e la scorza, 50 gr di olio di semi, 210 gr di farina, 60 gr di fecola di patate, 1/2 bustina di lievito, 200 gr di gelsi o qualsiasi frutta.",
    steps:
      "Montare con le fruste le uova con lo zucchero in modo da ottenere un composto gonfio e spumoso. Aggiungere il succo e la scorza di limone, l’olio, la farina, la fecola e il lievito setacciati. Se l’impasto risultasse troppo denso aggiungere un goccio di latte. Infine aggiungere la frutta passata nella farina. Versare il tutto in uno stampo da plum-cake. Cuocere a 180° per 40 minuti, fare la prova dello stecchino.",
    image: {
      src: "/images/plumcakeconfruttacongelata.PNG",
      alt: "Plumcake con frutta congelata",
    },
  },
  {
    page: "SecondCourse",
    id: 1015,
    name: "Involtini di pesce spada alla Messinese",
    dateOfTheRecipe: "Settembre 15, 2023",
    description:
      "Il pesce spada è un  tipo di pesce dalla grande versatilità in cucina, con poche calorie nonostante sia un pesce grasso (ma di grasso “buono”), dipende anche da come lo si prepara, e che si presta a molte preparazioni diverse. È una specie ittica piuttosto diffusa, che vive in acque temperate e che ha una grande diffusione anche nel Mediterraneo.Il pesce spada è un’ottima fonte di proteine, contiene sodio, ferro, potassio e  fosforo. Il pesce spada è consigliato anche a chi sta seguendo un regime dietetico dimagrante, a chi soffre di ipercolesterolemia, di diabete e di ipertensione. E’  un concentrato di Omega3, che sono particolarmente importanti per il funzionamento del nostro organismo regalando i livelli di colesterolo nel sangue. Essendo un pesce grande, si ha rischio di contaminazione da mercurio per cui non va mangiato ogni giorno, ma si può consumare tranquillamente una volta a settimana senza problemi, tranne per chi è in gravidanza è da evitare. Come mangiare il pesce spada in un modo non convenzionale? La ricetta che consiglio oggi è semplice e saporita: Braciole alla messinese. Sono involtini di pesce spada impanate nel pangrattato, capperi e all’interno un formaggio filante.",

    ingredients:
      "Per due persone: 500 gr di pesce spada, pangrattato q.b, prezzemolo q.b., olio di oliva q.b, 1 cucchiaino di Capperi (dissalati), 100 g Scamorza (provola), sale e pepe q.b.. ",
    steps:
      "Dal vostro pescivendolo fate tagliare il pesce spada a fettine sottili. Versate pangrattato, capperi ed il prezzemolo tagliuzzati, sale e pepe in un contenitore basso e largo. In un altro contenitore mettete l’olio. A questo punto siete pronti per realizzare i vostri involtini. Adagiate le sottili fette di pesce spada nell'olio d’oliva. Fatele sgocciolare, per eliminare l’olio in eccesso, e passatele nel pangrattato. Inserite un pezzetto di formaggio e realizzate degli involtini. Procedete in questo modo per tutte le fettine. Infilzate gli involtini di pesce spada alla Messinese su degli stecchi di legno e adagiateli su una teglia da forno. Se vi rimane il pangrattato mettetelo sopra agli involtini. Cuocete gli involtini in forno preriscaldato a 180° per circa 15 minuti, fino a doratura di entrambi i lati. Buon pranzo dalla vostra bio.nutrizionista Martina Bosso.",
    image: {
      src: "/images/involtiniPesceSpadaMessinese.PNG",
      alt: "Involtini di pesce spada alla Messinese",
    },
  },
  {
    page: "Dessert",
    id: 1016,
    name: "Biscotti di semola all’uvetta",
    dateOfTheRecipe: "Settembre 15, 2023",
    description:
      "La colazione oppure lo spuntino sono dei pasti che non vanno sottovalutati. Per questo vi consiglio questi gustosi Biscotti, se li volete rendere ancora più gustosi potete sostituire l’uvetta con le gocce di cioccolato.",

    ingredients:
      "265 gr farina di semola, 30 gr fecola di patate, 80 gr zucchero, 150 gr burro t.a., 1 uovo intero, 50 gr uvetta",
    steps:
      "Per preparare i biscotti all'uvetta per prima cosa mettete l'uvetta a bagno in acqua tiepida oppure in un pò di liquore (strega, rum). Mettete la farina in una ciotola capiente o nella planetaria e aggiungete la fecola, lo zucchero, il burro tagliato a pezzetti e l'uovo. Impastare velocemente con le mani e trasferire il composto sulla spianatoia. Strizzate l'uvetta e unitela all'impasto. Formate un panetto omogeneo senza impastare troppo. Rivestitelo di pellicola per alimenti e fatelo riposare in frigo per 20-30 minuti. Prendete dei pezzetti di impasto di 40 gr l'uno e formate delle palline. Schiacciatele leggermente con le mani e disponetele sulla placca del forno su un foglio di carta da forno. Cuocete a 180° per circa 30 minuti. I biscotti all'uvetta con farina di semola si conservano per diversi giorni in un contenitore sigillato, vi consiglio in uno di latta.",
    image: {
      src: "/images/biscottiSemolaUvetta.PNG",
      alt: "Biscotti di semola all’uvetta",
    },
  },
];

let mainCourse = database.filter((dessert) => dessert.page === "MainCourse");
let secondCourse = database.filter(
  (dessert) => dessert.page === "SecondCourse"
);
let dessert = database.filter((dessert) => dessert.page === "Dessert");

export function getDatabase() {
  return database;
}
export function getMainCourse() {
  return mainCourse;
}
export function getSecondCourse() {
  return secondCourse;
}
export function getDessert() {
  return dessert;
}

export function findMainCourse(mainCourseId) {
  return mainCourse.find((item) => item.id === mainCourseId);
}

export function findSecondCourse(secondCourseId) {
  return secondCourse.find((item) => item.id === secondCourseId);
}

export function findDessert(dessertId) {
  return dessert.find((item) => item.id === dessertId);
}
